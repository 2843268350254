import React from "react";
import { useStaticQuery, graphql} from "gatsby"
import { Col, Container, Row } from "react-bootstrap";

const GoHire = () => {
    const datacareers = useStaticQuery(graphql` 
    {
      allMarkdownRemark(
        sort: {order: DESC, fields: frontmatter___sort}
        filter: {frontmatter: { page: {eq: "careers"}}}
      ) {
        nodes {
          frontmatter {
            title
            location
            date
            url
          }
        }
      }
    }    
    `)

    const datacrs = datacareers.allMarkdownRemark.nodes    

  return (
    <section class="join-us">
        <Container>
            <Col lg={12}>
                <h2 class="text-center text-white">Current Openings</h2>
            </Col>
            <Row className="justify-content-center mt-5">
                <Col lg={8}>
                    <div className="careers-list">
                        {datacrs.map((datac) => (
                            <a href={datac.frontmatter.url} className="careers-wrapper d-flex flex-column mb-4" target="_blank" rel="noopener noreferrer">
                                <h3>{datac.frontmatter.title}</h3>
                                <p className="mb-0">{datac.frontmatter.location} · Posted {datac.frontmatter.date}</p>
                            </a>
                        ))}
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
  );
};

export default GoHire;