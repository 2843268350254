import React from "react"
import MainLogo from "../global/mainLogo"
import { Col, Container, Row } from "react-bootstrap"

import "../../sass/global/main.scss"

const CareersMain = () => {
  return (
    <div className="main">
      <Container>
        <Row>
          <Col md={12} lg={7} xl={6}>
            <div className="intro">
              <p className="service-label">CAREERS</p>
              <h1 className="title">Where work is play</h1>
              <p className="description">
                WowMakers is the sum total of the ideas, skills, talents and love and warmth of each and everyone here. Every new WowMaker adds a very important aspect that is unique to them. This is exactly why we can't wait for you to be a part of our family!
              </p>
            </div>
          </Col>
          <MainLogo />
        </Row>
      </Container>
    </div>
  )
}

export default CareersMain
