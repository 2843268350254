import { Link } from "gatsby";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const CareersNav = () => {

  return (
    <section className="carres-nav py-0">
        <Container fluid>
            <Row>
                <Col md={6} className="px-0">
                    <Link to="/blog" className="cn-url blog text-center d-flex justify-content-center position-relative">
                        <h2 class="text-white">Our Blog</h2>
                    </Link>
                </Col>
                <Col md={6} className="px-0" >
                    <Link to="/about" className="cn-url about text-center d-flex justify-content-center position-relative">
                        <h2 class="text-white">About the Team</h2>
                    </Link>                    
                </Col>                
            </Row>
        </Container>
    </section>
  );
};

export default CareersNav;