import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"

import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import CareersMain from "../../components/careers/careersMain"
import AboutWhatWeDo from "../../components/about/aboutWhatWeDo"
import AboutValues from "../../components/about/aboutValues"

import Awards from "../../components/careers/awards"
import GoHire from "../../components/careers/goHire"
import AboutWorkCulture from "../../components/about/aboutWorkCulture"
import CareersNav from "../../components/careers/careersNav"

import careersFeaturedImage from "../../../static/home/wowmakers.jpeg"

import "../../sass/pages/careers.scss"
import "../../sass/pages/about.scss"

export const Head = () => (
    <>
      <SeoHead
        ogImage={careersFeaturedImage}
        title="Careers At WowMakers"
        description="We're a 12-year old UI/UX design company specialized in user experience design (UX), user interface design (UI) & mobile and web engineering."
      />
    </> 
)

const Careers = () => {
    return(
        <Layout>
        <Seo
            bclass="careers"
            bid="careers"
        ></Seo>
        <ScrollIndicationHeightZero />
        <CareersMain />
        <AboutWhatWeDo />
        <AboutValues />
        <Awards />
        <GoHire />
        <AboutWorkCulture />
        <CareersNav />
        </Layout>
    )
}

export default Careers